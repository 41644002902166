import type { ReactNode } from 'react';

import { Box } from '../box/box';
import { Grid } from '../grid/grid';
import { PrimaryNav } from '../primary-nav/primary-nav';

interface BumblebeeLayoutProps {
  navItems: ReactNode;
  header: ReactNode;
  children: ReactNode;
}

export const BumblebeeLayout = ({
  children,
  header,
  navItems,
}: BumblebeeLayoutProps) => (
  <Grid layout="bumblebee" fullHeight>
    <Box
      Component="header"
      background="neutral500"
      padding={{
        x: 'basePos3',
        top: 'basePos4',
        bottom: 'basePos5',
      }}
    >
      {header}
    </Box>

    <PrimaryNav
      background="neutral500"
      gridItem={{ columnStart: '1', rowStart: '2' }}
    >
      {navItems}
    </PrimaryNav>

    <Grid
      layout="bumblebeeContainer"
      gridItem={{ columnStart: '2', rowStart: '2' }}
    >
      <Box
        Component="main"
        gridItem={{ columnStart: '2' }}
        spacing="base"
        padding={{ x: 'basePos6', bottom: 'basePos6' }}
        size={{ minWidth: 'none' }} // reset for flexbox children to stop overflowing
      >
        {children}
      </Box>
    </Grid>
  </Grid>
);
