import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shieldpay/bumblebee/button';
import { Column } from '@shieldpay/bumblebee/layout';
import { BumblebeeLayout } from '@shieldpay/bumblebee/layouts/bumblebee';
import { NavigationLogo } from '@shieldpay/bumblebee/navigation-logo';
import { PrimaryNav } from '@shieldpay/bumblebee/primary-nav';
import { Text } from '@shieldpay/bumblebee/text';

import { routes } from '../../routes';
import { BumblebeeIcon } from '../logos/bumblebee-icon';
import { BumblebeeLogo } from '../logos/bumblebee-logo';

interface LayoutProps {
  children: ReactNode;
}

const NavItems = () => (
  <>
    <PrimaryNav.Heading>
      <FormattedMessage id="hive.titles.overview" />
    </PrimaryNav.Heading>

    <PrimaryNav.Item to="/">
      {<FormattedMessage id="hive.titles.home" />}
    </PrimaryNav.Item>

    <PrimaryNav.Item to="/tokens">Tokens</PrimaryNav.Item>

    <PrimaryNav.Heading>
      <FormattedMessage id="hive.titles.components" />
    </PrimaryNav.Heading>

    {Object.entries(routes.components).map(([path, title]) => (
      <PrimaryNav.Item key={path} to={`components/${path}`}>
        {title}
      </PrimaryNav.Item>
    ))}
  </>
);

export const HiveLayout = ({ children }: LayoutProps) => {
  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <BumblebeeLayout
      header={
        <NavigationLogo to={'/'} icon={BumblebeeIcon} logo={BumblebeeLogo} />
      }
      navItems={<NavItems />}
    >
      <Column spacing="basePos4">{children}</Column>
      <Column spacing="base" alignContent={{ x: 'center', y: 'center' }}>
        <Text paragraph>
          <Button variant="text-underline" onClick={handleScrollToTop}>
            Back to top
          </Button>
        </Text>
      </Column>
    </BumblebeeLayout>
  );
};
