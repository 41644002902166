import type { ElementType, ReactNode } from 'react';

import type { StyleProps } from '@shieldpay/theme-provider-ui/styles';
import { getStyles } from '@shieldpay/theme-provider-ui/styles';
import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import * as styles from './grid.css';

export interface GridProps
  extends Partial<Pick<StyleProps, 'spacing' | 'gridItem'>> {
  Component?: ElementType;
  className?: string;
  fullHeight?: boolean;
  children: ReactNode;
  layout: keyof typeof styles.layout;
}

/**
 * Provides the outer element for a Grid layout with styling props.
 * For custom positioning of child elements there is a gridItemStyles function
 * to complement this component
 */
const Grid = ({
  children,
  className,
  fullHeight,
  spacing,
  layout,
  Component = 'div',
  gridItem,
  ...restProps
}: GridProps) => {
  return (
    <Component
      {...restProps}
      className={classFilter([
        getStyles({
          spacing,
          gridItem,
        }),
        styles.base,
        fullHeight && styles.fullHeight,
        layout && styles.layout[layout],
        className,
      ])}
    >
      {children}
    </Component>
  );
};

export { Grid };
