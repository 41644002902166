import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface Route {
  home: Record<string, ReactNode>;
  components: Record<string, ReactNode>;
}

export const routes: Route = {
  home: {
    '/': <FormattedMessage id="hive.titles.home" />,
  },
  components: {
    'action-menu': <FormattedMessage id="hive.components.actionMenu.title" />,
    breadcrumb: <FormattedMessage id="hive.components.breadcrumb.title" />,
    button: <FormattedMessage id="hive.components.button.title" />,
    card: <FormattedMessage id="hive.components.card.title" />,
    'card-controls': (
      <FormattedMessage id="hive.components.cardControls.title" />
    ),
    checkbox: <FormattedMessage id="hive.components.checkbox.title" />,
    'choice-list': <FormattedMessage id="hive.components.choiceList.title" />,
    'copy-text-icon': (
      <FormattedMessage id="hive.components.copyTextIcon.title" />
    ),
    'data-table': <FormattedMessage id="hive.components.dataTable.title" />,
    'date-input': <FormattedMessage id="hive.components.dateInput.title" />,
    divider: <FormattedMessage id="hive.components.divider.title" />,
    'document-uploader': (
      <FormattedMessage id="hive.components.documentUploader.title" />
    ),
    drawer: <FormattedMessage id="hive.components.drawer.title" />,
    dropdown: <FormattedMessage id="hive.components.dropdown.title" />,
    'file-download': (
      <FormattedMessage id="hive.components.fileDownload.title" />
    ),
    heading: <FormattedMessage id="hive.components.heading.title" />,
    icon: <FormattedMessage id="hive.components.icon.title" />,
    'input-field': <FormattedMessage id="hive.components.inputField.title" />,
    infoblock: <FormattedMessage id="hive.components.infoblock.title" />,
    layout: <FormattedMessage id="hive.components.layout.title" />,
    link: <FormattedMessage id="hive.components.link.title" />,
    list: <FormattedMessage id="hive.components.list.title" />,
    'loading-spinner': (
      <FormattedMessage id="hive.components.loadingSpinner.title" />
    ),
    modal: <FormattedMessage id="hive.components.modal.title" />,
    'paginated-table': (
      <FormattedMessage id="hive.components.paginatedTable.title" />
    ),
    pagination: <FormattedMessage id="hive.components.pagination.title" />,
    'password-input': (
      <FormattedMessage id="hive.components.passwordInput.title" />
    ),
    pill: <FormattedMessage id="hive.components.pill.title" />,
    'phone-number-input': (
      <FormattedMessage id="hive.components.phoneNumberInput.title" />
    ),
    'primary-nav': <FormattedMessage id="hive.components.primaryNav.title" />,
    'progress-indicator': (
      <FormattedMessage id="hive.components.progressIndicator.title" />
    ),
    'radio-button': <FormattedMessage id="hive.components.radioButton.title" />,
    search: <FormattedMessage id="hive.components.search.title" />,
    'sort-code-input': (
      <FormattedMessage id="hive.components.sortCodeInput.title" />
    ),
    table: <FormattedMessage id="hive.components.table.title" />,
    tabs: <FormattedMessage id="hive.components.tabs.title" />,
    tag: <FormattedMessage id="hive.components.tag.title" />,
    text: <FormattedMessage id="hive.components.text.title" />,
    'text-list': <FormattedMessage id="hive.components.textList.title" />,
    toast: <FormattedMessage id="hive.components.toast.title" />,
    'top-bar': <FormattedMessage id="hive.components.topBar.title" />,
    'validation-checklist': (
      <FormattedMessage id="hive.components.validationChecklist.title" />
    ),
  },
};
