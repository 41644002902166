import type { ElementType } from 'react';

import { useBumblebeeContext } from '@shieldpay/theme-provider-ui';

import { Box } from '../box/box';

import * as styles from './navigation-logo.css';

interface NavigationLogoProps {
  to: string;
  icon: ElementType;
  logo: ElementType;
}

/**
 * MVP solution for switching between two white coloured logos at different
 * breakpoints which are wrapped in a link. I expect us to completely replace this
 * ASAP once we decide on a good technique to switch components based on screen size.
 */
export const NavigationLogo = ({
  to,
  icon: Icon,
  logo: Logo,
}: NavigationLogoProps) => {
  const { NavLink } = useBumblebeeContext();

  return (
    <NavLink className={styles.link} to={to}>
      <Box Component={Logo} hide={{ xs: true, xxl: false }} />
      <Box Component={Icon} hide={{ xxl: true }} />
    </NavLink>
  );
};
